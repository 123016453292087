@import '~antd/dist/antd.css';
.ant-layout-header {
  height: 64px;
  padding: 0 20px;
  color: rgba(0, 0, 0, 0.85);
  line-height: 64px;
  background: #001529;
}

.ant-layout {
  background: #fff;
}

.ant-breadcrumb {
  border-bottom: 1px solid #f0f0f0;
  padding-bottom: 20px;
  margin-bottom: 20px !important;
  margin-top: 15px;
}

.lista-boletos {
  height: 200px;
  overflow: auto;
}

.loading-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  justify-items: center;
  height: 200px;
}

.form-wrapper {
  background: #f0f0f0f0;
  padding: 20px;
  border-radius: 7px;
  margin-bottom: 40px;
}

.ant-input-number {
  width: 100%;
}

.historico-list {
  margin: 0;
  border: 1px solid #ccc;
  padding: 0px;
  list-style: none;
  margin-bottom: 20px;
}

.historico-list li {
  font-size: 11px;
  padding: 5px;
}

.historico-list li:nth-child(odd) {
  background-color: #fff;
}

.historico-list li:nth-child(even) {
  background-color: #eaeaea;
}

.loadingWrapper {
  height: 90px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.loadingWrapper p {
  padding: 10px;
}

@media print {
  .no-print {
    display: none;
  }
  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  .mt20-print {
    margin-top: 20px;
  }
  .ant-breadcrumb {
    display: none;
  }
}

.site-layout-background {
  background: #fff;
}

.logo {
  display: inline-block;
  float: left;
  margin-right: 40px;
}

.devedor-name {
  font-size: 10px;
}

.devedor-name b {
  font-size: 12px;
}

.lista-acordos-contrato .ant-collapse-content-box {
  padding: 0;
}

.btn-list {
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  height: 100px;
  width: 100%;
}

.btn-list .ant-space-item {
  width: 100%;
  margin: 0 !important;
}

.btn-list .ant-space-item button {
  width: 100%;
  margin: 0 !important;
}

.box-remessa {
  background: #f1f1f1;
  padding: 20px;
  width: 100%;
  margin-bottom: 20px;
}

.frmAcordo {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
  align-items: center;
  align-content: space-between;
  min-height: 80px;
}

.situacao-acordo {
  padding: 0px 5px 0px 5px;
  color: #fff;
  border-radius: 6px;
}

.situacao-acordo--quebrado {
  background: red;
}

.update-row {
  margin-bottom: 20px;
  text-align: right;
}

.output_panel {
  margin-bottom: 40px;
}

.output_panel h4 {
  font-weight: bold;
}

code {
  font-size: 13px;
  font-family: monospace;
  color: limegreen;
  line-height: 15px;
  max-width: 100%;
  display: flex;
}

pre {
  background: #000;
  padding: 10px;
  border-radius: 4px;
}

.seletor-carteira {
  display: flex;
  flex-direction: column;
  padding-right: 15px;
  margin-top: 20px;
}

.filtros-form {
  display: flex;
  width: 100%;
  justify-content: flex-end;
}

.btn-bb {
  background-color: gold;
}

.btn-caixa {
  background: blue;
  color: #fff;
}

.filters-card .ant-card-body {
  padding: 10px;
}

.form-box {
  background-color: #FFF;
  padding: 20px;
}

.form-box p {
  margin: 0px;
}

.form-installments h3 {
  margin-bottom: 20px;
}

.installments {
  display: flex;
  align-items: center;
}

.installments-alt {
  position: relative;
  top: -30px;
  left: 100px;
}

.collum-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-footer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.btn-darkblue {
  color: #E6F7FF;
  border-color: #001529;
  background: #001529;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 2px 0 rgba(0, 0, 0, 0.045);
}

.btn-darkblue:hover {
  color: #E6F7FF;
  background: #000A13;
}

.btn-darkblue:active {
  background: #000A13;
}

.view-installment-box {
  background-color: #ccc;
}

.check-installments {
  color: #1890ff;
}

.check-installments:hover {
  color: #036bcd;
}

.delete-installments {
  color: #ff0000;
}

.delete-installments:hover {
  color: #7a0000;
}

.add-installments {
  color: #1890ff;
}

.view-installment-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #FAFAFA;
  padding: 10px;
}